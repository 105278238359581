import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PageLayout from 'src/components/PageLayout/PageLayout';
import PageSEO from 'src/components/PageSEO/PageSEO';
import QueryClientProvider from 'src/components/QueryClientProvider/QueryClientProvider';
import WebFormAws from 'src/components/WebFormAws/WebFormAws';
import WebFormContext from 'src/components/WebFormContext/WebFormContext';

function AwsPage({ location }) {
  return (
    <PageLayout>
      <WebFormContext>
        <PageSEO title="AWS könyv magyarul" description="AWS könyv magyarul" location={location} />
        <Container className="mb-6 mt-9">
          <Row>
            <Col xs={12}>
              <div className="mb-5 text-center">
                <h1>AWS könyv magyarul</h1>
              </div>
            </Col>
            <Col xs={12} className="mb-4">
              <StaticImage src="../images/cloud/aws_book_cover.jpg" alt="Image" />
            </Col>
            <Col className="col-xl-8 col-12 offset-xl-3 py-3">
              <p className="text-muted">
                A Cheppers lassan egy évtizede dolgozik az Amazon Web Services (AWS) szolgáltatásaival – a felhő és a devops koncepció
                kialakulását és kiteljesedését így közelről láthattuk és fejlődhettünk együtt azzal.
              </p>

              <p className="text-muted">
                A könyvünk célja, hogy a magyar nyelven értő, kommunikáló és dolgozó, a felhő és az AWS után érdeklődő olvasóink egy olyan –
                egyébként hiánypótló – kötetet forgathassanak, amely segít számukra eligazodni ebben a komplex, de valójában gyakran
                túlmisztifikált világban. Célunk volt az alapvető fogalmak ismertetése mellett az is, hogy az AWS legfontosabb
                szolgáltatásairól szülessen egy magyar nyelvű bemutató.
              </p>
              <p className="text-muted">
                Az&nbsp;
                <a className="font-weight-bold" href="#konyv-letoltes">
                  alábbi űrlap
                </a>
                &nbsp;kitöltését követően e-mailben küldjük ki azt a linket, amin keresztül elérheti PDF formátumban az e-könyvet.
                Amennyiben kérdése van, várjuk a megkeresését akár honlapunk kapcsolatfelvételi űrlapján keresztül, akár az&nbsp;
                <a className="font-weight-bold" href="mailto:aws@cheppers.com">
                  aws@cheppers.com
                </a>
                &nbsp;email címen.
              </p>
            </Col>
            <Col xs={12}>
              <div className="d-flex justify-content-center mt-5 mb-4">
                <StaticImage className="mx-2" src="../images/cloud/partner_advanced_tier.svg" alt="Aws logo" style={{ maxWidth: 300 }} />
                <StaticImage className="mx-2" src="../images/cloud/partner_amazon_rds.svg" alt="Aws logo" style={{ maxWidth: 300 }} />
                <StaticImage className="mx-2" src="../images/cloud/partner_public_sector.svg" alt="Aws logo" style={{ maxWidth: 300 }} />
                <StaticImage className="mx-2" src="../images/cloud/partner_immersion_day.svg" alt="Aws logo" style={{ maxWidth: 300 }} />
              </div>
            </Col>
          </Row>
        </Container>
        <div id="konyv-letoltes">
          <QueryClientProvider>
            <WebFormAws />
          </QueryClientProvider>
        </div>
      </WebFormContext>
    </PageLayout>
  );
}

export default AwsPage;
