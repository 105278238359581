import React from 'react';
import { QueryClientProvider as Provider, QueryClient } from 'react-query';

const queryClient = new QueryClient();

function QueryClientProvider({ children }) {
  return <Provider client={queryClient}>{children}</Provider>;
}

export default QueryClientProvider;
